import React from "react";
import styled from "styled-components";
import { theme } from "@upsolve/ui";
import ThumbsUpIcon from "./images/chat-thumbs-up.svg";
import ThumbsUpFilledIcon from "./images/chat-thumbs-up-filled.svg";
import ThumbsDownIcon from "./images/chat-thumbs-down.svg";
import ThumbsDownFilledIcon from "./images/chat-thumbs-down-filled.svg";
import { ChatTooltip } from "./ChatTooltip";

const FeedbackContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 8px;
`;

const IconButton = styled.button<{ isActive?: boolean }>`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s;
  border-radius: 4px;

  &:hover {
    background: ${theme.colors.white[900]};
  }
`;

interface IFeedbackButtonsProps {
  messageId: number;
  wasHelpful?: boolean | null;
  onFeedback: (messageId: number, isHelpful: boolean | null) => void;
}

export const FeedbackButtons: React.FC<IFeedbackButtonsProps> = ({ messageId, wasHelpful, onFeedback }) => {
  return (
    <FeedbackContainer>
      <ChatTooltip content="Helpful" backgroundColor={theme.colors.black[100]} textColor={theme.colors.white[900]}>
        <IconButton
          isActive={wasHelpful === true}
          onClick={() => onFeedback(messageId, wasHelpful === true ? null : true)}
          aria-label="Mark as helpful"
        >
          {wasHelpful === true ? <ThumbsUpFilledIcon /> : <ThumbsUpIcon />}
        </IconButton>
      </ChatTooltip>

      <ChatTooltip content="Not helpful" backgroundColor={theme.colors.black[100]} textColor={theme.colors.white[900]}>
        <IconButton
          isActive={wasHelpful === false}
          onClick={() => onFeedback(messageId, wasHelpful === false ? null : false)}
          aria-label="Mark as not helpful"
        >
          {wasHelpful === false ? <ThumbsDownFilledIcon /> : <ThumbsDownIcon />}
        </IconButton>
      </ChatTooltip>
    </FeedbackContainer>
  );
};
