import React, { useEffect, useState } from "react";
import { getStateFromZipcode, getStateMedianFamilyIncome } from "@upsolve/shared";
import DebtAdvisorGraphic from "./images/debt-advisor-graphic.svg";
// @ts-ignore
import {
  getBankruptcyEligibilityResults,
  getConsolidationEligibilityResults,
  getDmpEligibilityResults,
  getSettlementEligibilityResults,
} from "./eligibilityFunctions";
import { ResultsComparison } from "./ResultsComparison";
import { ModalContent } from "../modals/TriageModal";
import {
  QuestionHeading,
  IntroHeading,
  DescriptionCentered,
  IntroSmallText,
  Description,
  LoadingWrapper,
  Controls,
  CurrencyInput,
  ErrorBox,
  Button,
  Input,
} from "./Components";
import { StatusQuoScreen } from "./StatusQuoScreen";
import styled from "styled-components";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

type TControls = {
  onNext: () => void;
  onBack: () => void;
  onRestart: () => void;
};

export type TFormState = {
  zipcode: string;
  hasIncome: boolean | undefined;
  hasGoodCreditScore: boolean | undefined;
  unsecuredDebtEstimate: number | undefined;
  householdSize: number | undefined;
  passesMeansTest: boolean | undefined;
  availableMonthlyFunds: number | undefined;
  hasAccessToCapital: boolean | undefined;
  isDetailsOpen: boolean;
};

export const initialFormState: TFormState = {
  zipcode: "",
  hasIncome: undefined,
  hasGoodCreditScore: undefined,
  unsecuredDebtEstimate: undefined,
  householdSize: undefined,
  passesMeansTest: undefined,
  availableMonthlyFunds: undefined,
  hasAccessToCapital: undefined,
  isDetailsOpen: false,
};

export type TScreenProps = {
  controls: TControls;
  state: TFormState;
  updateState: (state: Partial<TFormState>) => void;
  trackStepAction: (actionId: string, extras?: Record<string, any>) => void;
  trackImpression: (subComponentName: string) => void;
};

const isZipcodeValid = (value: string) => {
  return getStateFromZipcode(value) !== undefined;
};

const Intro = ({ controls }: TScreenProps) => {
  return (
    <ModalContent>
      <DebtAdvisorGraphic />
      <IntroHeading>Welcome to Upsolve!</IntroHeading>
      <DescriptionCentered>
        Take our free, 5-minute questionnaire to learn which debt relief options best fit your needs. We'll help you
        make a decision and get started.
      </DescriptionCentered>
      <Controls>
        <Button onClick={() => controls.onNext()} style={{ marginBottom: "10px" }} data-test-label="begin-button">
          Begin
        </Button>
      </Controls>
      <IntroSmallText>
        <a href="https://upsolve.org/privacy-policy/" target="_blank">
          Your information is safe with us.
        </a>
      </IntroSmallText>
    </ModalContent>
  );
};

const ZipcodeAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const isValidZipcode = isZipcodeValid(state.zipcode);
  const [inputsAreDirty, setInputsAreDirty] = useState(false);

  const setZipcode = (value: string) => {
    setInputsAreDirty(true);
    updateState({ zipcode: value });
  };

  const onSubmit = () => {
    setInputsAreDirty(true);
    if (!isValidZipcode) {
      return;
    }
    trackStepAction(`submit`, { zipcode: state.zipcode });
    controls.onNext();
  };

  return (
    <ModalContent>
      <QuestionHeading>What is your zipcode?</QuestionHeading>
      <div className="content-node__input">
        <Input
          type="string"
          onChange={(e) => setZipcode(e.target.value)}
          value={state.zipcode}
          data-hj-allow
          data-test-label="zipcode"
        />
      </div>
      <ErrorBox hasError={!isValidZipcode && inputsAreDirty}>Invalid Zipcode</ErrorBox>
      <Controls>
        <Button
          onClick={onSubmit}
          disabled={!isValidZipcode}
          style={{ marginBottom: "10px" }}
          data-test-label="next-button"
        >
          Next
        </Button>
      </Controls>
    </ModalContent>
  );
};

const DebtAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const setDebt = (value: string) => {
    const cleanedValue = value.replace(/[^0-9\.\-]+/g, "");
    updateState({ unsecuredDebtEstimate: Number(cleanedValue) });
  };

  return (
    <ModalContent>
      <QuestionHeading>
        How much debt do you have from credit cards, medical bills, car loans, or personal loans?{" "}
      </QuestionHeading>
      <Description>
        It’s OK to guess - just do your best! <br />
        Do not include student loan debt in this total.
      </Description>
      <CurrencyInput
        onChange={(e: { target: { value: string } }) => setDebt(e.target.value)}
        value={state.unsecuredDebtEstimate || ""}
        placeholder={18000}
        data-test-label="debt"
      />
      <Controls>
        <Button
          disabled={!state.unsecuredDebtEstimate}
          onClick={() => {
            trackStepAction("submit", { unsecuredDebtEstimate: state.unsecuredDebtEstimate });
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
          data-test-label="next-button"
        >
          Next
        </Button>
      </Controls>
    </ModalContent>
  );
};

const HouseholdAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const setHouseholdSize = (value: string) => {
    const cleanedValue = value.replace(/[^0-9\.\-]+/g, "");
    updateState({ householdSize: Number(cleanedValue) });
  };
  return (
    <ModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>How many people are in your household?</QuestionHeading>
      <Description>This includes you and all the people who rely on you for money in your house.</Description>
      <div className="content-node__input">
        <Input
          type="string"
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder={"2"}
          value={state.householdSize || ""}
          onChange={(e) => setHouseholdSize(e.target.value)}
          data-hj-allow
          data-test-label="household-size"
        />
      </div>
      <Controls>
        <Button
          disabled={!state.householdSize}
          onClick={() => {
            trackStepAction("submit", { householdSize: state.householdSize });
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
          data-test-label="next-button"
        >
          Next
        </Button>
      </Controls>
    </ModalContent>
  );
};

const HasIncomeScreen = ({ controls, updateState, trackStepAction }: TScreenProps) => {
  const onAnswer = (answer: boolean) => {
    updateState({ hasIncome: answer });
    trackStepAction("submit", { hasIncome: answer });
    controls.onNext();
  };

  return (
    <ModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>Do you receive any monthly income?</QuestionHeading>
      <Description>
        If you receive income from any kind of job, friends and family, investments, or government support, answer{" "}
        <b>yes</b>.
      </Description>
      <Description>
        If anyone in your household receives income, answer <b>yes</b>.
      </Description>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }} data-test-label="yes-button">
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }} data-test-label="no-button">
          No
        </Button>
      </Controls>
    </ModalContent>
  );
};

const MeansTestAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  if (state.zipcode === undefined) {
    return <>Error: missing zipcode</>;
  }

  if (state.householdSize === undefined) {
    return <>Error: missing household size</>;
  }

  const usState = getStateFromZipcode(state.zipcode);

  if (!usState) {
    return <>Error: could not derive state from zipcode</>;
  }

  const medianFamilyIncome = (getStateMedianFamilyIncome(usState, state?.householdSize) / 12).toLocaleString(
    undefined,
    {
      maximumFractionDigits: 0,
    }
  );

  const onAnswer = (answer: boolean) => {
    updateState({ passesMeansTest: !answer });
    trackStepAction("submit", { passesMeansTest: !answer });
    controls.onNext();
  };

  return (
    <ModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>
        {`Is your household monthly income greater than $${medianFamilyIncome}?`}
      </QuestionHeading>
      <Description>
        ${medianFamilyIncome} is the median family income for your household size in your state. If your household earns
        less, you may be eligible for certain debt options.
      </Description>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }} data-test-label="yes-button">
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }} data-test-label="no-button">
          No
        </Button>
      </Controls>
    </ModalContent>
  );
};

const AvailableMonthlyFundsAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const setAvailableMonthlyFunds = (value: string) => {
    const cleanedValue = value.replace(/[^0-9\.\-]+/g, "");
    updateState({ availableMonthlyFunds: cleanedValue === "" ? undefined : Number(cleanedValue) });
  };

  return (
    <ModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>
        How much money can you afford to spend each month to pay down your debt?
      </QuestionHeading>
      <CurrencyInput
        onChange={(e: { target: { value: string } }) => setAvailableMonthlyFunds(e.target.value)}
        value={state.availableMonthlyFunds === undefined ? "" : state.availableMonthlyFunds}
        placeholder={400}
        data-test-label="monthly-funds"
      />
      <Controls>
        <Button
          disabled={state.availableMonthlyFunds === undefined}
          onClick={() => {
            trackStepAction("submit", { availableMonthlyFunds: state.availableMonthlyFunds });
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
          data-test-label="next-button"
        >
          Next
        </Button>
      </Controls>
    </ModalContent>
  );
};

const AccessToCapitalAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  if (state.unsecuredDebtEstimate === undefined) {
    return <div>Error</div>;
  }

  const onAnswer = (answer: boolean) => {
    updateState({ hasAccessToCapital: answer });
    trackStepAction("submit", { hasAccessToCapital: answer });
    controls.onNext();
  };

  // rounded to 10s place
  const halfOfDebt = Math.round((0.5 * state.unsecuredDebtEstimate) / 10) * 10;
  return (
    <ModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>Do you have access to ${halfOfDebt}?</QuestionHeading>
      <Description>
        Include money you have now plus anything you think you could borrow from friends or family to repay your debt.
      </Description>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }} data-test-label="yes-button">
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }} data-test-label="no-button">
          No
        </Button>
      </Controls>
    </ModalContent>
  );
};

const HasGoodCreditScoreAsk = ({ controls, updateState, trackStepAction }: TScreenProps) => {
  const onAnswer = (answer: boolean) => {
    updateState({ hasGoodCreditScore: answer });
    trackStepAction("submit", { hasGoodCreditScore: answer });
    controls.onNext();
  };

  return (
    <ModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>Is your credit score over 700?</QuestionHeading>
      <Description>If you're not sure it’s OK to guess!</Description>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }} data-test-label="yes-button">
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }} data-test-label="no-button">
          No
        </Button>
      </Controls>
    </ModalContent>
  );
};

const CalculatingScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CalculatingScreen = ({ controls }: TScreenProps) => {
  useEffect(() => {
    setTimeout(() => controls.onNext(), 1000 * 2);
  }, []);
  return (
    <CalculatingScreenWrapper>
      <DotLottieReact
        src="https://lottie.host/f566cec5-8353-4cd5-bb67-5ff48b89b40e/Bhmc5RUAOJ.json"
        loop
        autoplay
        style={{ height: "200px", width: "350px" }}
      />
      <LoadingWrapper>
        <QuestionHeading style={{ marginBottom: "10px", textAlign: "center" }}>
          Determining your debt relief options
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </QuestionHeading>
      </LoadingWrapper>
      <br />
    </CalculatingScreenWrapper>
  );
};

//TODO: refactor this and the ResultComparison
export const ResultsScreen = ({ state, controls, trackStepAction, trackImpression, updateState }: TScreenProps) => {
  if (state.hasIncome === undefined) {
    return <div>Error: missing hasIncome</div>;
  }
  if (state.unsecuredDebtEstimate === undefined) {
    return <div>Error: missing unsecuredDebtEstimate</div>;
  }

  if (state.availableMonthlyFunds === undefined) {
    return <div>Error: missing availableMonthlyFunds</div>;
  }

  if (state.hasGoodCreditScore === undefined) {
    return <div>Error: missing hasGoodCreditScore </div>;
  }

  if (state.householdSize === undefined) {
    return <div>Error: missing householdSize</div>;
  }

  const dmpEligibilityResults = getDmpEligibilityResults(
    state.hasIncome,
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds
  );
  const bankruptcyEligibilityResults = getBankruptcyEligibilityResults(
    state.passesMeansTest || !state.hasIncome,
    state.unsecuredDebtEstimate,
    state.zipcode,
    state.availableMonthlyFunds
  );

  const settlementEligibilityResults = getSettlementEligibilityResults(
    state.passesMeansTest || false,
    state.availableMonthlyFunds,
    state.hasAccessToCapital || false,
    state.unsecuredDebtEstimate
  );
  const consolidationEligibilityResults = getConsolidationEligibilityResults(
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds,
    state.hasGoodCreditScore
  );
  const setIsDetailsOpen = (isDetailsOpen: boolean) => {
    updateState({ isDetailsOpen: isDetailsOpen });
  };

  return (
    <ResultsComparison
      onRecompute={controls.onRestart}
      // @ts-ignore
      userInput={state}
      results={{
        consolidationEligibilityResults,
        dmpEligibilityResults,
        bankruptcyEligibilityResults,
        settlementEligibilityResults,
      }}
      trackStepAction={trackStepAction}
      trackImpression={trackImpression}
      setIsDetailsOpen={setIsDetailsOpen}
    />
  );
};

export const screens = {
  Intro,
  ZipcodeAsk,
  DebtAsk,
  HouseholdAsk,
  HasIncomeScreen,
  MeansTestAsk,
  AvailableMonthlyFundsAsk,
  AccessToCapitalAsk,
  HasGoodCreditScoreAsk,
  CalculatingScreen,
  StatusQuoScreen,
  ResultsScreen,
};
