import React, { useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { theme } from "@upsolve/ui";

interface ChatTooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
}

const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TooltipContent = styled.div<{ backgroundColor?: string; textColor?: string }>`
  position: fixed;
  background-color: ${({ backgroundColor }) => backgroundColor || theme.colors.gray[900]};
  color: ${({ textColor }) => textColor || "white"};
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Averta;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 6000;
  text-align: center;
  white-space: nowrap;
  opacity: 0;
  animation: tooltipFadeIn 0.1s ease-in 2s forwards;

  @keyframes tooltipFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ChatTooltip: React.FC<ChatTooltipProps> = ({ children, content, backgroundColor, textColor }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const updatePosition = () => {
    if (wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      setPosition({
        top: rect.top - 4,
        left: rect.left + rect.width / 2,
      });
    }
  };

  React.useEffect(() => {
    if (showTooltip) {
      updatePosition();
      window.addEventListener("scroll", updatePosition);
      window.addEventListener("resize", updatePosition);
    }
    return () => {
      window.removeEventListener("scroll", updatePosition);
      window.removeEventListener("resize", updatePosition);
    };
  }, [showTooltip]);

  return (
    <TooltipWrapper
      ref={wrapperRef}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      onClick={() => setShowTooltip(!showTooltip)}
    >
      {children}
      {showTooltip &&
        ReactDOM.createPortal(
          <TooltipContent
            onClick={() => setShowTooltip(false)}
            backgroundColor={backgroundColor}
            textColor={textColor}
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
              transform: "translate(-50%, -100%)",
            }}
          >
            {content}
          </TooltipContent>,
          document.body
        )}
    </TooltipWrapper>
  );
};
