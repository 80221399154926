import React, { useEffect, useState } from "react";
import { richTextFromMarkdown } from "@contentful/rich-text-from-markdown";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

interface IMessageTextProps {
  text: string;
}

export const MessageText: React.FC<IMessageTextProps> = ({ text }) => {
  const [parsedContent, setParsedContent] = useState<React.ReactNode>(text);

  useEffect(() => {
    richTextFromMarkdown(text)
      .then((document) => {
        setParsedContent(documentToReactComponents(document));
      })
      .catch(() => {
        setParsedContent(text);
      });
  }, [text]);

  return <>{parsedContent}</>;
};
