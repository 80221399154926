import axios from "axios";
import React, { useEffect, useState } from "react";

// OPTIMIZE: Do this as a context provider/consumer so multiple components aren't firing off requests
function withGeoLocation(Child) {
  const GeoLocated = (props) => {
    const [geoLocation, setGeoLocation] = useState(null);
    // Get/Fetch GeoLocation and Reset State to pass to child props
    useEffect(() => {
      async function getAndSetGeoLocation() {
        let newGeoLocation = localStorage.getItem("Upsolve.geoLocation");
        // - If we have it, just set
        if (typeof newGeoLocation === "object" && newGeoLocation != null)
          return setGeoLocation(JSON.parse(newGeoLocation));
        // - If we don't fetch and set
        newGeoLocation = await axios
          // .get("https://freegeoip.app/json")
          .get(`${UPSOLVE_API_URL}/v1/geographys/geoip`)
          .then((res) => res.data)
          .catch((e) => console.warn(`GeoIP Lookup Failed - ${e.message}`));
        localStorage.setItem("Upsolve.geoLocation", JSON.stringify(newGeoLocation));
        setGeoLocation(newGeoLocation);
      }
      getAndSetGeoLocation();
    }, []);
    const newProps = { ...props, geoLocation };
    return <Child {...newProps} />;
  };
  return GeoLocated;
}

export default withGeoLocation;
